import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ArchiveUser1 } from '../../../components/images/support/users/ArchiveUser1.generated';
import { ArchiveUser2 } from '../../../components/images/support/users/ArchiveUser2.generated';
import { ArchiveUser3 } from '../../../components/images/support/users/ArchiveUser3.generated';
import { ArchiveUser4 } from '../../../components/images/support/users/ArchiveUser4.generated';
import { ArchiveUser5 } from '../../../components/images/support/users/ArchiveUser5.generated';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "archive-a-user-"
    }}>{`Archive a User 🗄`}</h1>
    <p>{`This section shows how to "archive" users in Canyou. Archiving a user will revoke their
access to the platform as well as changing their profile status to "archived". Users
that are "archived" are found in a seperate section from the users who are still active in
the business. Archived users still retain all their information and workflows
that they have completed in Canyou and can be "un-archived" at any time.`}</p>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Navigate to the Users menu and click on the user you want to archive.`}</li>
    </ol>

    <ArchiveUser1 mdxType="ArchiveUser1" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Click on the user details menu (the 3 dots) and then click `}<strong parentName="li">{`Archive`}</strong></li>
    </ol>

    <ArchiveUser2 mdxType="ArchiveUser2" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Click `}<strong parentName="li">{`Archive`}</strong></li>
    </ol>

    <ArchiveUser3 mdxType="ArchiveUser3" />
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Great work 🙌 now we can see the user has been archived. We can still review any of their
profile details and workflows, they just won't appear in the list with our current users. If
at any time we wish to "un-archive" a user we can do it from the menu like so:`}</li>
    </ol>

    <ArchiveUser4 mdxType="ArchiveUser4" />
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`To review our "archived" users we can do this by clicking on the
`}<strong parentName="li">{`Archived`}</strong>{` button in the users menu.`}</li>
    </ol>

    <ArchiveUser5 mdxType="ArchiveUser5" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      